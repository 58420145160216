import React from "react";
import { Button } from "@ibrahimstudio/button";
import { Input } from "@ibrahimstudio/input";
import { link } from "../utilities/helper";
import { PhoneIcon, MailIcon, MapsIcon } from "../components/icons";
import styles from "./styles/contact.module.css";

const Contact = ({ sectionId }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("form submitted");
  };

  return (
    <section
      id={sectionId}
      section-view-id={sectionId}
      className={styles.contact}
    >
      <div className={styles.contactHeadWrap}>
        <div className={styles.contactHead}>
          <h1 className={styles.contactTitle}>
            Ready to elevate your agricultural imports?
          </h1>
          <p className={styles.contactDesc}>
            Contact us now to discuss your requirements, explore partnership
            opportunities, and experience excellence in every export.
          </p>
        </div>
        <form className={styles.contactForm} onSubmit={handleSubmit}>
          <div className={styles.inputWrap}>
            <Input
              id="client-name"
              radius="md"
              labelText="Your Name"
              type="text"
              placeholder="e.g. John Doe"
              isRequired
            />
            <Input
              id="client-phone"
              radius="md"
              labelText="Phone Number"
              type="tel"
              placeholder="0885 xxxx"
              isRequired
            />
          </div>
          <div className={styles.inputWrap}>
            <Input
              id="client-email"
              radius="md"
              labelText="Email Address"
              type="email"
              placeholder="myaccount@mail.com"
              isRequired
            />
            <Input
              id="client-subject"
              radius="md"
              labelText="Subject"
              type="text"
              placeholder="Email Subject"
              isRequired
            />
          </div>
          <div className={styles.inputWrap}>
            <Input
              id="client-message"
              variant="textarea"
              rows={4}
              radius="md"
              labelText="Message"
              type="text"
              placeholder="Input your message here ..."
              isRequired
            />
          </div>
          <Button
            id="send-contact-form"
            type="submit"
            buttonText="Send Message"
            radius="md"
          />
        </form>
      </div>
      <div className={styles.contactBody}>
        <div className={styles.contactCard}>
          <div
            style={{
              display: "flex",
              width: "var(--pixel-35)",
              alignItems: "center",
              justifyContent: "center",
              color: "inherit",
            }}
          >
            <PhoneIcon width="100%" height="100%" />
          </div>
          <div className={styles.contactcardHead}>
            <h1 className={styles.contactcardTitle}>Phone</h1>
            <h2 className={styles.contactcardSubtitle}>+628 888 555</h2>
          </div>
        </div>
        <div
          className={styles.contactCard}
          onClick={() => link("mailto:contact@adinata-indonesia.com")}
        >
          <div
            style={{
              display: "flex",
              width: "var(--pixel-35)",
              alignItems: "center",
              justifyContent: "center",
              color: "inherit",
            }}
          >
            <MailIcon width="100%" height="100%" />
          </div>
          <div className={styles.contactcardHead}>
            <h1 className={styles.contactcardTitle}>Email</h1>
            <h2 className={styles.contactcardSubtitle}>
              contact@adinata-indonesia.com
            </h2>
          </div>
        </div>
        <div
          className={styles.contactCard}
          onClick={() => link("https://maps.app.goo.gl/JUTbtZkwoZjMxLyw5")}
        >
          <div
            style={{
              display: "flex",
              height: "var(--pixel-28)",
              alignItems: "center",
              justifyContent: "center",
              color: "inherit",
            }}
          >
            <MapsIcon width="100%" height="100%" />
          </div>
          <div className={styles.contactcardHead}>
            <h1 className={styles.contactcardTitle}>Location</h1>
            <h2 className={styles.contactcardSubtitle}>See Location on Maps</h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
