import React, { useState, useEffect, useRef } from "react";
import { Button } from "@ibrahimstudio/button";
import { IgIcon, InIcon, FbIcon, MenuIcon, CloseIcon } from "./icons";
import { scrollView } from "@ibrahimstudio/function";
import styles from "./styles/nav.module.css";
import menu from "./styles/mobile-menu.module.css";

const Nav = ({ componentId }) => {
  const [scrolled, setScrolled] = useState(false);
  const [activeTab, setActiveTab] = useState("main-screen");
  const [menuOpen, setMenuOpen] = useState(false);

  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const yOffset = -80;
      const sections = document.querySelectorAll("[section-view-id]");

      sections.forEach((section) => {
        const top =
          section.getBoundingClientRect().top + window.scrollY + yOffset;
        const height = section.offsetHeight;
        if (window.scrollY >= top && window.scrollY < top + height) {
          const id = section.getAttribute("section-view-id");
          setActiveTab(id);
          const pageTitle = getPageTitle(id);
          document.title = pageTitle;
          if (id !== "main-screen") {
            window.history.replaceState(null, null, `#${id}`);
          } else {
            window.history.replaceState(null, null, window.location.pathname);
          }
        }
        if (window.scrollY > 100) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      });
    };

    const getPageTitle = (id) => {
      switch (id) {
        case "about-us":
          return "About Us | Adinata Ekspor Indonesia";
        case "our-facility":
          return "Our Facility | Adinata Ekspor Indonesia";
        case "our-products":
          return "Our Products | Adinata Ekspor Indonesia";
        case "our-gallery":
          return "Our Gallery | Adinata Ekspor Indonesia";
        case "contact-us":
          return "Contact Us | Adinata Ekspor Indonesia";
        default:
          return "Premium Agricultural Exports | Adinata Ekspor Indonesia";
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <React.Fragment>
      <nav
        id={componentId}
        className={`${styles.nav} ${scrolled ? styles.scrolled : ""}`}
      >
        <img
          onClick={() => scrollView(-70, "main-screen")}
          className={styles.navLogoIcon}
          loading="lazy"
          alt={componentId}
          src="/img/nav-logo.png"
        />
        <div className={styles.navMenu}>
          <div className={styles.navTab}>
            <button
              className={`${styles.tabButton} ${
                activeTab === "main-screen" ? styles.active : ""
              }`}
              onClick={() => scrollView(-70, "main-screen")}
            >
              <div className={styles.tabButtonText}>Home</div>
            </button>
            <button
              className={`${styles.tabButton} ${
                activeTab === "about-us" ? styles.active : ""
              }`}
              onClick={() => scrollView(-70, "about-us")}
            >
              <div className={styles.tabButtonText}>About</div>
            </button>
            <button
              className={`${styles.tabButton} ${
                activeTab === "our-facility" ? styles.active : ""
              }`}
              onClick={() => scrollView(-70, "our-facility")}
            >
              <div className={styles.tabButtonText}>Offers</div>
            </button>
            <button
              className={`${styles.tabButton} ${
                activeTab === "our-products" ? styles.active : ""
              }`}
              onClick={() => scrollView(-70, "our-products")}
            >
              <div className={styles.tabButtonText}>Products</div>
            </button>
            <button
              className={`${styles.tabButton} ${
                activeTab === "our-gallery" ? styles.active : ""
              }`}
              onClick={() => scrollView(-70, "our-gallery")}
            >
              <div className={styles.tabButtonText}>Gallery</div>
            </button>
          </div>
          <Button
            id="contact-nav"
            size="sm"
            buttonText="Contact Us"
            radius="md"
            onClick={() => scrollView(-70, "contact-us")}
          />
          <div className={styles.burgerIcon} onClick={openMenu}>
            <MenuIcon
              height="100%"
              width="100%"
              color="var(--color-secondary)"
            />
          </div>
        </div>
      </nav>
      {menuOpen && (
        <MobileMenu
          componentId="mobile-menu"
          activeTab={activeTab}
          onClose={closeMenu}
        />
      )}
    </React.Fragment>
  );
};

const MobileMenu = ({ componentId, activeTab, onClose }) => {
  const [isClosing, setIsClosing] = useState(false);
  const ref = useRef(null);

  const handleClick = (sectionId) => {
    scrollView(-70, sectionId);
    setIsClosing(true);
  };

  const handleClose = () => {
    setIsClosing(true);
  };

  useEffect(() => {
    if (isClosing) {
      const animationDuration = 500;
      setTimeout(() => {
        onClose();
      }, animationDuration);
    }
  }, [isClosing, onClose]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsClosing(true);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setIsClosing]);

  return (
    <React.Fragment>
      <div
        id={componentId}
        ref={ref}
        className={`${menu.mobileMenu} ${isClosing ? menu.close : ""}`}
      >
        <div className={menu.menuHeader}>
          <img
            className={styles.navLogoIcon}
            loading="lazy"
            alt={componentId}
            src="/img/nav-logo.png"
          />
          <button className={menu.closeButton} onClick={handleClose}>
            <CloseIcon
              width="100%"
              height="100%"
              color="var(--color-secondary)"
            />
          </button>
        </div>
        <div className={menu.menuNav}>
          <button
            className={`${menu.menuButton} ${
              activeTab === "main-screen" ? menu.active : ""
            }`}
            onClick={() => handleClick("main-screen")}
          >
            <div className={menu.menuButtonText}>Home</div>
          </button>
          <button
            className={`${menu.menuButton} ${
              activeTab === "about-us" ? menu.active : ""
            }`}
            onClick={() => handleClick("about-us")}
          >
            <div className={menu.menuButtonText}>About Us</div>
          </button>
          <button
            className={`${menu.menuButton} ${
              activeTab === "our-facility" ? menu.active : ""
            }`}
            onClick={() => handleClick("our-facility")}
          >
            <div className={menu.menuButtonText}>Offers</div>
          </button>
          <button
            className={`${menu.menuButton} ${
              activeTab === "our-products" ? menu.active : ""
            }`}
            onClick={() => handleClick("our-products")}
          >
            <div className={menu.menuButtonText}>Products</div>
          </button>
          <button
            className={`${menu.menuButton} ${
              activeTab === "our-gallery" ? menu.active : ""
            }`}
            onClick={() => handleClick("our-gallery")}
          >
            <div className={menu.menuButtonText}>Gallery</div>
          </button>
        </div>
        <div className={menu.menuSocial}>
          <div className={menu.menuSocialTitle}>Connect Socially :</div>
          <div className={menu.menuSocialIcon}>
            <div className={menu.socialIcon}>
              <div
                style={{
                  display: "flex",
                  width: "var(--pixel-25)",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IgIcon width="100%" color="var(--color-secondary)" />
              </div>
            </div>
            <div className={menu.socialIcon}>
              <div
                style={{
                  display: "flex",
                  width: "var(--pixel-25)",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FbIcon width="100%" color="var(--color-secondary)" />
              </div>
            </div>
            <div className={menu.socialIcon}>
              <div
                style={{
                  display: "flex",
                  width: "var(--pixel-25)",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <InIcon width="100%" color="var(--color-secondary)" />
              </div>
            </div>
          </div>
        </div>
        <button
          className={menu.menuCta}
          onClick={() => handleClick("contact-us")}
        >
          <div className={menu.menuCtaText}>Contact Us</div>
        </button>
      </div>
      <div
        className={`${menu.mobileMenuBg} ${isClosing ? menu.close : ""}`}
        style={{
          content: "''",
          position: "fixed",
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
          zIndex: "1001",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      ></div>
    </React.Fragment>
  );
};

export default Nav;
