import React from "react";
import { StarIcon, NatureIcon, GlobeIcon } from "../components/icons";
import OffersCard from "../components/offers-card";
import styles from "./styles/offers.module.css";

const Offers = ({ sectionId }) => {
  return (
    <section
      id={sectionId}
      section-view-id={sectionId}
      className={styles.offers}
    >
      <div className={styles.offersHead}>
        <h1 className={styles.offersTitle}>Why Choose Adinata Indonesia?</h1>
        <p className={styles.offersDesc}>
          Based in the lush landscapes of Indonesia, we specialize in the
          exportation of premium agricultural produce to destinations worldwide.
        </p>
      </div>
      <div className={styles.offersBody}>
        <OffersCard
          iconContent={<StarIcon width="100%" height="100%" />}
          cardTitle="Uncompromised Quality"
          cardDesc="Every product bearing our name undergoes rigorous quality control to ensure it meets the highest standards."
        />
        <OffersCard
          iconContent={<NatureIcon width="100%" height="100%" />}
          cardTitle="Sustainable Practices"
          cardDesc="We prioritize sustainable sourcing and production methods, fostering harmony between nature and commerce."
        />
        <OffersCard
          iconContent={<GlobeIcon width="100%" height="100%" />}
          cardTitle="Global Reach"
          cardDesc="With a global network and local insights, we navigate international markets with precision and proficiency."
        />
      </div>
    </section>
  );
};

export default Offers;
