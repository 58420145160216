export const productsData = [
  {
    imageSrc: "/img/gambir-4.webp",
    prodTitle: "Uncaria (Gambir)",
    prodDesc:
      "Discover the richness of Gambir, renowned for its medicinal properties and superior quality. Sourced from the heart of Indonesian forests, our Gambir stands as a testament to purity and potency.",
  },
  {
    imageSrc: "/img/cinnamon-1.webp",
    prodTitle: "Cinnamon (Kayu Manis)",
    prodDesc:
      "Experience the warmth and aroma of authentic Indonesian Cinnamon. Our carefully selected Cinnamon offers a delightful blend of flavor and fragrance, perfect for culinary and wellness endeavors worldwide.",
  },
  {
    imageSrc: "/img/lidi-sawit-1.webp",
    prodTitle: "Oil Palm Fronds (Lidi Sawit)",
    prodDesc:
      "From our palm groves to your doorstep, experience the versatility of Oil Palm Fronds. Whether for landscaping, animal husbandry, or eco-friendly endeavors, our Lidi Sawit stands tall in quality and sustainability.",
  },
  {
    imageSrc: "/img/betelnut-1.webp",
    prodTitle: "Betel Nut (Pinang)",
    prodDesc:
      "Embark on a journey of tradition and taste with our premium Betel Nut. Sourced from select plantations, our Pinang embodies the essence of cultural heritage and botanical excellence.",
  },
];

export const galleryData = [
  {
    imageSrc: "/img/gambir-1.webp",
  },
  {
    imageSrc: "/img/lidi-sawit-1.webp",
  },
  {
    imageSrc: "/img/gambir-2.webp",
  },
  {
    imageSrc: "/img/gambir-3.webp",
  },
  {
    imageSrc: "/img/cinnamon-1.webp",
  },
  {
    imageSrc: "/img/gambir-4.webp",
  },
  {
    imageSrc: "/img/lidi-sawit-2.webp",
  },
  {
    imageSrc: "/img/gambir-5.webp",
  },
];
