import React from "react";
import Nav from "../components/nav";
import Hero from "../sections/hero";
import About from "../sections/about";
import Feature from "../sections/feature";
import Offers from "../sections/offers";
import Products from "../sections/products";
import Gallery from "../sections/gallery";
import Contact from "../sections/contact";
import Footer from "../components/footer";
import styles from "./styles/home.module.css";

const HomePage = () => {
  return (
    <div id="adinata-indonesia-homepage" className={styles.main}>
      <Nav componentId="top-navigation" />
      <Hero sectionId="main-screen" />
      <About sectionId="about-us" />
      <Feature
        version="revert"
        imageSrc="/img/cinnamon-2.webp"
        sectionTitle="Our Vision"
        sectionDesc="At Adinata Ekspor Indonesia, our vision is to become the preferred choice for international customers seeking top-notch Uncaria, Cinnamon, Palm Fronds, and Areca Nut products."
      />
      <Feature
        imageSrc="/img/gambir-2.webp"
        sectionTitle="Our Mission"
        sectionDesc="Our mission at Adinata Ekspor Indonesia is to consistently deliver superior quality products, foster long-term relationships with customers, and expand our global reach."
      />
      <Offers sectionId="our-facility" />
      <Products sectionId="our-products" />
      <Gallery sectionId="our-gallery" />
      <Contact sectionId="contact-us" />
      <Footer componentId="bottom-navigation" />
    </div>
  );
};

export default HomePage;
