import React from "react";
import styles from "./styles/about.module.css";

const About = ({ sectionId }) => {
  return (
    <section
      id={sectionId}
      section-view-id={sectionId}
      className={styles.about}
    >
      <div className={styles.aboutHead}>
        <h2 className={styles.aboutSubtitle}>ABOUT US</h2>
        <h1 className={styles.aboutTitle}>
          A Journey where Nature's Treasures meet Global Markets.
        </h1>
        <p className={styles.aboutDesc}>
          Based in the lush landscapes of Indonesia, we specialize in the
          exportation of premium agricultural produce to destinations worldwide.
        </p>
      </div>
      <div className={styles.aboutBody}>
        <iframe
          className={styles.videoEmbed}
          src="https://www.youtube.com/embed/fzjo9_yZ3Vc"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </section>
  );
};

export default About;
