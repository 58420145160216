import React from "react";
import { productsData } from "../utilities/data";
import ProdCard from "../components/prod-card";
import styles from "./styles/products.module.css";

const Products = ({ sectionId }) => {
  return (
    <section
      id={sectionId}
      section-view-id={sectionId}
      className={styles.products}
    >
      <div className={styles.productsHead}>
        <h2 className={styles.productsSubtitle}>OUR PRODUCTS</h2>
        <h1 className={styles.productsTitle}>
          Our Diverse Range of Exquisite Products
        </h1>
        <p className={styles.productsDesc}>
          Based in the lush landscapes of Indonesia, we specialize in the
          exportation of premium agricultural produce to destinations worldwide.
        </p>
      </div>
      <div className={styles.productsBody}>
        {productsData.map((product, index) => (
          <ProdCard
            key={index}
            version={index % 2 === 0 ? "" : "revert"}
            imageSrc={product.imageSrc}
            prodTitle={product.prodTitle}
            prodDesc={product.prodDesc}
          />
        ))}
      </div>
    </section>
  );
};

export default Products;
